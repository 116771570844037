$direction: 'rtl';
$fonts: ();
@import '../paritals/vars';
@import '../paritals/mixins';
@import '../paritals/layout';

@import 'vars';
// dark mode theme override everything ---
body {
	&.dark {
		color: $color-gray-500;
		background: $color-dark-650;


	}
}
