.template-1 {
	.template {
        &__wrapper{

        }
		&__header {
			&__image {
				@apply w-36;
			}
		}
	}
}
