//colors --
$color-primary: #004956;
$color-primary-l: #004D5A;
$color-primary-d: #00414d;
$color-primary-darker: #1e2f32;
$color-secondary: #76E8CD;
$color-secondary-d: #73E7CC;
$color-secondary-50: #BAF3E6;
$color-secondary-25: #CFF7EE;
$color-light: #BBF3E5;
$color-white: #fff;
$color-black: #000;

$color-gray-100: #f8f8f8;
$color-gray-200: #eee;
$color-gray-300: #ddd;
$color-gray-400: #bbbbbb;
$color-gray-500: #cccccc;

$color-dark-100: #999;
$color-dark-200: #666;
$color-dark-300: #444;
$color-dark-400: #333333;

$color-success: #00af6c;
$color-alert: #f8e71c;
$color-info: #5196F3;
$color-warning: #ffaf44;
$color-warning-2: #ffc62a;
$color-danger: #f55157;
$input-height: 36px;

// breakpoints ---
$max-breakpoint: 2000;
$screen-desktop: 1600px;
$screen-desktop-large: 1920px;
$screen-desktop-small: 1439px;
$screen-laptop: 1200px;
$screen-laptop-small: 1024px;
$screen-tablet-l: 992px;
$screen-tablet-p: 768px;
$screen-phones: 767px;
$screen-phablet: 480px;
$screen-mobile: 320px;

// --- text
$base-size: 16px;
$text-lg: 18px;
$text-x-md: 17px;
$text-md: 16px;
$text-sm: 15px;
$text-x-sm: 14px;
$text-xx-sm: 13px;

$line-height: 1.4;

$trans-speed: 0.35s;
$box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.07);

$b-radius: 5px;
$b-radius--min: 4px;
$b-radius--large: 8px;

// fonts ---
$font-regular: "PingARLT";
$font-bold: "PingARLT";

// default direction --- rtl;
$direction: 'rtl';
$left: null;
$right: null;

@if ($direction == 'rtl') {
  $left: left;
  $right: right;
} @else {
  $left: right;
  $right: left;
}

$font-main: "PingARLT";
$font-sallaIcon:"sallaicons";