.card {
	@apply relative w-[290px] rounded-md border border-gray-200 dark:border-dark-300 border-collapse;
	&__overlay {
		@apply absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center;
	}
	&__body {
		@apply absolute z-10 bottom-0 left-0 w-full bg-white dark:bg-dark-700 dark:text-gray-400 dark:border-dark-300;
	}
	&__title {
		@apply text-dark-300 text-lg mb-1 font-medium dark:text-gray-400;
	}
	&__description {
		@apply text-dark-200 text-base font-light truncate dark:text-dark-100;
	}
	&__actions {
		@apply flex justify-between items-center w-full border-t border-gray-200 p-4 h-20 dark:bg-dark-700 dark:text-gray-400 dark:border-dark-300;
	}
}
