.react-select {
	&__control {
		@apply border-gray-200 dark:bg-dark-700 dark:border-dark-400 #{!important};
		&--is-focused {
			@apply border-secondary-25 outline-secondary-25 dark:border-dark-400 dark:outline-dark-400 #{!important};
		}
	}

	&__multi-value {
		@apply mx-1 py-0.5 px-1 text-primary-l rounded bg-secondary-25  #{!important};
		&__remove {
			@apply text-primary-l #{!important};
			&:hover {
				@apply bg-secondary-50 #{!important};
			}
		}
	}

	&__option {
		@apply text-dark-200 dark:text-dark-100 #{!important};
		&--is-focused {
			@apply bg-secondary-50 border-secondary-50  dark:bg-secondary-50/10 #{!important};
		}
		&--is-selected {
			@apply bg-secondary-25 #{!important};
		}
	}

	&__dropdown-indicator {
		svg {
			@apply fill-dark-300 w-[17px] h-[17px] #{!important};
		}
	}
}
