.panel {
	@apply border rounded-t-md my-4 dark:bg-dark-700 dark:border-dark-400;

	&__header {
		@apply bg-gray-50 flex justify-between items-center px-4 py-5 text-dark-200 dark:bg-dark-800 dark:text-dark-100 dark:border-gray-400;
	}

	&__title {
		@apply text-xl;
	}

	&__button {
		@apply outline outline-1 outline-secondary px-2 py-1 text-sm;
	}

	&__body {
		@apply transition-all duration-300 ease-in-out;
	}
}
